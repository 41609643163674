<template>
  <v-container class="px-8 py-8">
    <v-row class="pa-4">
      <div class="text text-h5 font-weight-bold">
        Kirim Notifikasi ke Aplikasi Mobile
      </div>
    </v-row>
    <v-row :justify="'center'">
      <v-col cols="12" md="8">
        <v-card rounded="lg">
          <v-container class="pa-10">
            <v-form ref="form">
              <v-text-field
                v-model="title"
                label="Title"
                placeholder="Title Notifikasi"
                dense
                outlined
                required
                type="text"
                :rules="titleRules"
              ></v-text-field>
              <v-text-field
                v-model="body"
                label="Body"
                placeholder="Body Notifikasi"
                dense
                outlined
                required
                type="text"
                :rules="bodyRules"
              >
              </v-text-field>
              <v-select
                v-model="category"
                :items="categories"
                label="Kategori"
                outlined
                :rules="categoryRules"
                prepend-inner-icon="mdi-shape"
                dense
                @change="onCategoryChange"
              >
              </v-select>
              <v-select
                v-model="target"
                :items="targets"
                :label="targetType"
                outlined
                dense
                v-if="showTarget"
                @change="onTargetChange"
              >
              </v-select>
            </v-form>
          </v-container>
        </v-card>
        <v-btn class="mt-2" block color="primary" @click="validate">
          Kirim
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" width="500">
      <v-card rounded="lg">
        <v-card-title class="text-h5 primary white--text">
          Konfirmasi
        </v-card-title>

        <v-card-text class="subtitle-1">
          <p>Apakah semua data yang dimasukkan telah benar?</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="showDialog = false">
            Batal
          </v-btn>
          <v-btn color="primary" @click="submit"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoadingDialog v-if="onLoading" :show-dialog="onLoading"></LoadingDialog>
    <AlertDialog
      :show-dialog="alertDialog"
      :message="alertMessage"
      :type="alertType"
      @onchange="onAlertDialogChange"
    >
    </AlertDialog>
  </v-container>
</template>

<script>
import service from "../../services/index.js";

import { BASE_URL } from "../../constant";
import LoadingDialog from "@/components/LoadingDialog.vue";
import AlertDialog from "@/components/AlertDialog.vue";

export default {
  name: "NotificationView",
  data() {
    return {
      title: null,
      body: null,
      category: null,
      target: null,
      titleRules: [(v) => !!v || "Judul harus diisi"],
      bodyRules: [(v) => !!v || "Body harus diisi"],
      categoryRules: [(v) => !!v || "Kategori harus dipilih"],
      targetType: null,
      showTarget: false,
      categories: [
        {
          text: "Semua",
          value: "all",
        },
        {
          text: "Petugas",
          value: "petugas",
        },
        {
          text: "Desa",
          value: "desa",
        },
        {
          text: "User",
          value: "user",
        },
      ],
      targets: [],
      onLoading: false,
      showDialog: false,
      alertDialog: false,
      alertMessage: "",
      alertType: "Sukses",
    };
  },
  methods: {
    async onCategoryChange(value) {
      switch (value) {
        case "all":
          this.showTarget = false;
          this.target = null;
          break;
        case "petugas":
          this.showTarget = false;
          this.target = null;
          break;
        case "desa":
          var response = await this.getDesa();
          var desas = response.data.data;

          this.targets = desas.map((desa) => {
            return {
              text: desa["nama"],
              value: desa["id"],
            };
          });
          this.target = this.targets[0].value;
          this.showTarget = true;
          break;
        case "user":
          var usersResponse = await this.getUsers();
          var users = usersResponse.data.data;
          this.targets = users.map((user) => {
            return {
              text: user.nama + " (" + user.role + ")",
              value: user.id,
            };
          });
          this.target = this.targets[0].value;
          this.showTarget = true;
          break;
      }
    },

    async getDesa() {
      var response = await service.getData(BASE_URL + "/desa/list");

      return response;
    },

    async getUsers() {
      var query = {
        pageSize: 1000,
      };
      var response = await service.getData(BASE_URL + "/users", query);

      return response;
    },

    async validate() {
      if (this.$refs.form.validate()) {
        this.showDialog = true;
      }
    },

    async submit() {
      var body = {
        title: this.title,
        body: this.body,
        category: this.category,
        target: this.target,
      };
      console.log(body);
      this.showDialog = false;
      this.onLoading = true;

      var response = await service.createData(
        BASE_URL + "/notification/send",
        body
      );

      if (response.data.sukses) {
        this.alertType = "Sukses";
        this.alertMessage = "Notifikasi berhasil terkirim";
        this.alertDialog = true;

        this.$refs.form.reset();
      } else {
        this.alertType = "Gagal";
        this.alertMessage = "Notifikasi gagal terkirim";
        this.alertDialog = true;
      }

      setTimeout(function () {
        location.reload();
      }, 2000);

      this.onLoading = false;
    },
    onAlertDialogChange(e) {
      this.alertDialog = e;
    },
    onTargetChange(value) {
      console.log(value);
      this.target = value;
    },
  },
  mounted() {
    this.category = this.categories[0].value;
  },
  computed: {},
  components: {
    AlertDialog,
    LoadingDialog,
  },
};
</script>
<style scoped>
.test {
  background-color: violet;
}
</style>
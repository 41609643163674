<template>
  <v-container class="px-8 py-8">
    <v-row class="pa-4">
      <div class="text text-h5 font-weight-bold">
        Daftar Laporan DBD Masyarakat
      </div>
    </v-row>
    <v-row>
      <v-col md="2" xs="12">
        <v-select
          :items="years"
          label="Tahun"
          v-model="selectedYear"
          v-on:change="onSelectedYearChange"
          dense
          outlined
        >
        </v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="3" cols="12">
        <v-text-field
          label="Cari"
          @input="onSearchChange"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row overflow-auto>
      <v-col>
        <v-card class="pa-4 rounded-lg">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    No
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Nama
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Desa
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Umur
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Tanggal Lapor
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Tanggal Sakit
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    No HP
                  </th>
                  <th
                    class="text-right subtitle-1 font-weight-bold primary--text"
                  >
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in laporans" :key="item.id">
                  <td class="text-left">
                    {{ ++i + (currentPage - 1) * dataPerPage }}
                  </td>
                  <td class="text-left">{{ item.nama }}</td>
                  <td class="text-left">{{ item.desa.nama }}</td>
                  <td class="text-left">{{ item.age }}</td>
                  <td class="text-left">{{ item.dateInput }}</td>
                  <td class="text-left">{{ item.dateSick }}</td>
                  <td class="text-left">{{ item.phoneNumber }}</td>
                  <td class="text-right">
                    <v-btn
                      x-small
                      color="primary"
                      @click="toDetail(item.id)"
                      dark
                    >
                      Details
                      <v-icon right> mdi-link </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row :justify="'center'">
      <div v-if="currentPage == 1">
        <v-btn icon @click="onPrevPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon class="primary white--text">
          {{ currentPage }}
        </v-btn>
        <v-btn
          v-if="currentPage + 2 <= totalPage"
          icon
          @click="gotoPage(currentPage + 1)"
        >
          {{ currentPage + 1 }}
        </v-btn>
        <v-btn
          v-if="currentPage + 2 <= totalPage"
          icon
          @click="gotoPage(currentPage + 2)"
        >
          {{ currentPage + 2 }}
        </v-btn>
        <v-btn icon>
          <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <div v-else-if="currentPage == totalPage">
        <v-btn icon @click="onPrevPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          v-if="currentPage - 2 > 0"
          icon
          @click="gotoPage(currentPage - 2)"
        >
          {{ currentPage - 2 }}
        </v-btn>
        <v-btn icon @click="gotoPage(currentPage - 1)">
          {{ currentPage - 1 }}
        </v-btn>
        <v-btn icon class="primary white--text">
          {{ currentPage }}
        </v-btn>
        <v-btn icon>
          <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <div v-else>
        <v-btn icon @click="onPrevPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon @click="gotoPage(currentPage - 1)">
          {{ currentPage - 1 }}
        </v-btn>
        <v-btn icon class="primary white--text">
          {{ currentPage }}
        </v-btn>
        <v-btn icon @click="gotoPage(currentPage + 1)">
          {{ currentPage + 1 }}
        </v-btn>
        <v-btn icon>
          <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>
  
<script>
import router from "@/router";

import service from "../../services/index.js";

import { BASE_URL } from "../../constant";

import { months } from "../../constant";

export default {
  props: ["flush"],
  name: "LaporanMasyarakatView",
  data() {
    return {
      laporans: [],
      years: [],
      selectedYear: undefined,
      currentYear: 2022,
      currentPage: 1,
      totalPage: 0,
      totalData: 0,
      dataPerPage: 0,
      search: "",
      flushType: undefined,
      flushMessage: undefined,
    };
  },
  methods: {
    async getLaporans(currentPage) {
      var query = {
        currentPage,
        search: this.search,
        year: this.currentYear,
      };

      var response = await service.getData(BASE_URL + "/laporan", query);

      if (response.status == 200) {
        this.currentIndex = currentPage;
        this.laporans = response.data.data;
        this.dataPerPage = response.data.metadata.dataPerPage;
        this.totalPage = response.data.metadata.totalPage;

        this.parseDateDate;
      }
    },

    async onNextPage() {
      if (this.currentPage >= this.totalPage) {
        return;
      }
      this.getLaporans(++this.currentPage);
    },

    async onPrevPage() {
      if (1 != this.currentPage) {
        this.getLaporans(--this.currentPage);
      }
    },

    async gotoPage(page) {
      if (!(page > this.totalPage) || !(page < 1)) {
        this.currentPage = page;
        this.getLaporans(this.currentPage);
      }
    },

    async addUser() {
      router.push({
        name: "users-create",
      });
    },

    async onSearch(value) {
      this.search = value;
      this.currentPage = 1;
      this.getLaporans(this.currentPage, value);
    },

    debounce(func, delay) {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    },

    async onSearchChange(value) {
      this.debounce(this.onSearch(value), 1000);
    },

    async getYears() {
      var response = await service.getData(BASE_URL + "/pendataan/years");

      var years = response.data.data;
      if (years) {
        this.years = years.map((year) => {
          return {
            text: `${year.year}`,
            value: year.year,
          };
        });
      }

      this.selectedYear = this.years[this.years.length - 1];
      this.currentYear = this.selectedYear.value;
    },

    async onSelectedYearChange(value) {
      this.currentYear = value;
      this.currentPage = 1;
      this.getLaporans(this.currentPage);
    },

    async toDetail(laporanId) {
      router.push({
        name: "laporan-masyarakat-detail",
        params: {
          laporanId,
        },
      });
    },
  },
  async mounted() {
    await this.getYears();
    this.getLaporans(this.currentPage);
    if (this.flush) {
      console.log("hey jude");
      console.log(this.flush.message);
      this.flushType = this.flush.type;
      this.flushMessage = this.flush.message;
    }
  },
  computed: {
    parseDateDate() {
      return this.laporans.map((laporan) => {
        var dateInput = new Date(laporan["dateInput"]);
        var dateSick = new Date(laporan["dateSick"]);

        laporan["dateInput"] = `${dateInput.getDate()} ${
          months[dateInput.getMonth()]
        } ${dateInput.getFullYear()}`;
        laporan["dateSick"] = `${dateSick.getDate()} ${
          months[dateSick.getMonth()]
        } ${dateSick.getFullYear()}`;

        return laporan;
      });
    },
  },
};
</script>
  
<template>
    <v-container class="px-8 py-8">
        <v-row class="pa-4" :justify="'center'">
            <v-col cols="12" md="6">
                <v-card rounded="lg">
                    <v-card-title class="primary white--text">
                        <span class="text-h6">Tambah Artikel</span>
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container class="py-5">
                                <v-text-field v-model="title" label="Judul" placeholder="Judul Artikel" dense outlined
                                    required :rules="titleRules">
                                </v-text-field>
                                <v-textarea rows="10" v-model="content" outlined name="content" label="Konten"
                                    :rules="contentRules">
                                </v-textarea>
                                <v-file-input label="Gambar" outlined accept="image/png, image/jpeg, image/bmp"
                                    prepend-icon="mdi-camera" :rules="fileRules" dense @change="onFileChange">
                                </v-file-input>
                                <v-text-field v-model="author" label="Author" placeholder="Penulis" dense outlined
                                    required :rules="authorRules">
                                </v-text-field>

                                <v-btn @click="submit" color="primary">
                                    Submit
                                </v-btn>

                                <v-dialog v-model="showDialog" width="500">

                                    <v-card rounded="lg">
                                        <v-card-title class="text-h5 primary white--text">
                                            Konfirmasi
                                        </v-card-title>

                                        <v-card-text class="subtitle-1">
                                            <p>Apakah semua data yang dimasukkan telah benar?</p>
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn color="primary" text @click="showDialog = false">
                                                Batal
                                            </v-btn>
                                            <v-btn color="primary" @click="createArticle">
                                                Submit
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>

                    </v-card-actions>
                </v-card>

                <LoadingDialog v-if="onLoading" :show-dialog=onLoading></LoadingDialog>
                <AlertDialog :show-dialog="alertDialog" :message=alertMessage :type=alertType
                    @onchange="onAlertDialogChange">
                </AlertDialog>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import LoadingDialog from '@/components/LoadingDialog.vue';
import AlertDialog from '@/components/AlertDialog.vue';

import service from '../../services/index'
import { BASE_URL } from '@/constant';
import router from '@/router';

export default {
    name: 'ArticlesCreateView',
    data() {
        return {
            FILE: null,
            valid: true,
            title: '',
            content: '',
            author: '',
            titleRules: [
                v => !!v || 'Judul harus diisi',
            ],
            authorRules: [
                v => !!v || 'Author harus diisi',
            ],
            contentRules: [
                v => !!v || 'Konten harus diisi',
            ],
            fileRules: [
                value => !value || value.size < 2000000 || 'Image tidak boleh lebih dari 2 MB!',
            ],
            showDialog: false,
            onLoading: false,
            alertDialog: false,
            alertMessage: '',
            alertType: "Sukses",
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.showDialog = true
            }
            // this.onLoading = true
        },
        async createArticle() {
            this.showDialog = false
            this.onLoading = true
            var data = new FormData()

            data.append('image', this.FILE, this.FILE.name)
            data.append('title', this.title)
            data.append('content', this.content)
            data.append('author', this.author)

            try {
                var response = await service.createData(BASE_URL + '/articles', data)

                if (response.status == 200) {
                    this.alertType = "Sukses"
                    this.alertMessage = "Artikel berhasil ditambahkan"
                    this.alertDialog = true

                    this.$refs.form.reset()

                    setTimeout(function () {
                        router.replace({ name: 'articles' })
                    }, 2000)
                }
            } catch (error) {
                console.log('gagal buat users')
                this.alertType = "Gagal"
                this.alertMessage = "Gagal menambahkan artikel"
                this.alertDialog = true
            }

            this.onLoading = false
        },
        onAlertDialogChange(e) {
            this.alertDialog = e
        },
        onFileChange(files) {
            console.log('ke trigger bos')
            this.FILE = files
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmPassword || "Password tidak sama";
        }
    },
    components: {
        LoadingDialog,
        AlertDialog
    }
}
</script>
<template>
    <v-dialog v-model="dialog" @input="handleInput" width="500">

        <v-card rounded="lg">
            <v-container class="text-center">
                <v-card-text>
                    <v-icon v-if="type === 'Sukses'" color="success" size="100">mdi-check-circle</v-icon>
                    <v-icon v-if="type === 'Gagal'" color="primary" size="100">mdi-close-circle</v-icon>
                </v-card-text>
                <span class="body-1">{{ message }}</span>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    model: {
        prop: 'showDialog',
        event: 'input'
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "Sukses"
        },
        message: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialog: this.showDialog
        }
    },
    created() {
        this.dialog = this.showDialog
    },
    methods: {
        handleInput() {
            this.$emit('onchange', this.dialog)
        }
    },
    watch: {
        showDialog: function (val) {
            this.dialog = val
        }
    },
    name: 'AlertDialog',
}
</script>
<template>
    <v-dialog v-model=dialog width="500" persistent>

        <v-card rounded="lg" class="pt-4">
            <v-container class="text-center">
                <v-card-text>
                    <v-progress-circular :size="70" :width="7" color="primary" indeterminate>
                    </v-progress-circular>
                </v-card-text>
                <span class="body-1">Mohon Tunggu ...</span>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },

    },
    data: () => ({
        dialog: false
    }),
    created() {
        this.dialog = this.showDialog
    },
    name: 'LoadingDialog',
}
</script>
<template>
    <div style="height: 700px; width: 100%; padding: 10px;" class="container">

        <l-map v-if="showMap" :zoom="zoom" :center="center" :options="mapOptions"
            style="height: 80%; width: 100%; z-index: 0;" @update:center="centerUpdate" @update:zoom="zoomUpdate">
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker v-for="pendataan in pendataans" :key="pendataan.id" :lat-lng="pendataan.latlng">
                <l-popup>
                    <div>
                        <span class="font-weight-bold">{{ pendataan.nama }}</span>
                        <v-list>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Rumah Diperiksa
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-chip small color="success">
                                        {{ pendataan['rumahDiperiksa'] }}
                                    </v-chip>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Jumlah Positif
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-chip small color="primary">
                                        {{ pendataan['jumlahPositif'] }}
                                    </v-chip>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Warga Positif
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-chip small color="primary">
                                        {{ pendataan['wargaPositif'] }}
                                    </v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </div>
                </l-popup>
            </l-marker>
        </l-map>
        <v-container>
            <v-row>
                <v-col md="2" xs="12">
                    <v-select :items="years" label="Tahun" v-model="selectedYear" v-on:change="onSelectedYearChange"
                        dense outlined>
                    </v-select>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
  
<script>
import service from "../../services/index"
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { BASE_URL } from "@/constant";

export default {
    name: "PemeteaanView",
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
    },
    data() {
        return {
            zoom: 11,
            center: L.latLng(-4.0790421, 120.2693642),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            withPopup: L.latLng(47.41322, -1.219482),
            withTooltip: L.latLng(47.41422, -1.250482),
            currentZoom: 10,
            currentCenter: L.latLng(47.41322, -1.219482),
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5
            },
            showMap: false,
            pendataans: [],
            selectedYear: null,
            currentYear: 2022,
            years: []
        };
    },
    methods: {
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        showLongText() {
            this.showParagraph = !this.showParagraph;
        },
        innerClick() {
            alert("Click!");
        },
        async getPendataan() {

            var query = {
                year: this.currentYear
            }
            try {
                var response = await service.getData(BASE_URL + '/pendataan/all', query)

                if (response.status == 200) {
                    this.pendataans = response.data.data
                    this.setLatLng
                    this.showMap = true
                }
            } catch (error) {
                console.log(error)
            }

        },
        async getYears() {
            var response = await service.getData(BASE_URL + '/pendataan/years')

            var years = response.data.data
            if (years) {
                this.years = years.map((year) => {

                    return {
                        text: `${year.year}`,
                        value: year.year
                    }
                })
            }

            this.selectedYear = this.years[this.years.length - 1]
            this.currentYear = this.selectedYear.value
        },
        async onSelectedYearChange(value) {
            this.currentYear = value
            this.getPendataan()
        },
    },
    async mounted() {
        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png')
        });



        await this.getYears()
        await this.getPendataan()
    },
    computed: {
        setLatLng() {
            return this.pendataans.map(pendataan => {
                pendataan['latlng'] = L.latLng(parseFloat(pendataan.latitude), parseFloat(pendataan.longitude))
                return pendataan
            })

        }
    }
};
</script>
  
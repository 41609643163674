<template>
    <v-container class="px-8 py-8">
        <v-row class="pa-4">
            <v-list-item class="text-left" v-if="!isLoading">
                <v-list-item-content>
                    <v-list-item-title class="title primary--text">
                        {{ puskesmas.nama }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ `${puskesmas.alamat}` }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-row>
        <v-row v-if="!isLoading">
            <v-col cols="12" md="6" :align="'start'">
                <v-card rounded-lg class="py-4 px-4">
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-home-group</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    Jumlah Rumah
                                </v-list-item-title>
                                <v-list-item-subtitle>Jumlah rumah di {{ puskesmas.nama }}</v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip color="primary">{{ puskesmas.jumlahRumah }}</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-account-multiple</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    Jumlah Warga
                                </v-list-item-title>
                                <v-list-item-subtitle>Jumlah warga di {{ puskesmas.nama }}</v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip color="primary">{{ puskesmas.jumlahWarga }}</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    Rumah Diperiksa
                                </v-list-item-title>
                                <v-list-item-subtitle>Jumlah rumah yang telah dikunjungi</v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip color="success">{{ puskesmas.rumahDiperiksa }}</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    Rumah Positif
                                </v-list-item-title>
                                <v-list-item-subtitle>Jumlah rumah yang diketahui positif</v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip color="red white--text">{{ puskesmas.jumlahPositif }}</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    Warga Positif
                                </v-list-item-title>
                                <v-list-item-subtitle>Jumlah warga yang diketahui positif</v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip color="red white--text">{{ puskesmas.wargaPositif }}</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    ABJ
                                </v-list-item-title>
                                <v-list-item-subtitle>Angka bebas jentik {{ puskesmas.nama }}</v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip color="success white--text">{{ puskesmas.abj.toFixed(2) }} %</v-chip>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    Insiden Rate
                                </v-list-item-title>
                                <v-list-item-subtitle>Rata-rata insiden pada {{ puskesmas.nama }}</v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-action>
                                <v-chip color="success white--text">{{ puskesmas.ir.toFixed(2) }}</v-chip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    Progress
                                </v-list-item-title>
                                <v-progress-linear height="25"
                                    :value="Math.ceil(this.puskesmas.jumlahRumah / this.puskesmas.rumahDiperiksa)"
                                    striped color="primary" rounded>

                                    {{ puskesmas.rumahDiperiksa == 0 ? 0 : Math.ceil(this.puskesmas.jumlahRumah /
        puskesmas.rumahDiperiksa)
}} %
                                </v-progress-linear>
                            </v-list-item-content>

                        </v-list-item>
                    </v-list>

                </v-card>
            </v-col>
            <v-col cols="12" md="6" :align="'start'">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="text-h6">List Pendataan</span>
                    </v-card-title>
                    <v-card-text class="py-0">
                        <v-timeline align-top dense>
                            <v-timeline-item small v-for="(data, i) in puskesmas.Pendataan" :key="i" color="primary">
                                <v-card color="primary white--text">
                                    <v-card-title class="font-weight-bold subtitle-1">

                                        {{ `${days[data.date.getDay()]}, ${data.date.getDate()}
                                        ${months[data.date.getMonth()]}
                                        ${data.date.getFullYear()}`
}}
                                    </v-card-title>
                                    <v-card-text class="white text--primary">
                                        <v-list>

                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle class="font-weight-bold">{{ data.desa.nama }}
                                                    </v-list-item-subtitle>

                                                </v-list-item-content>

                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Rumah yang diperiksa
                                                    </v-list-item-subtitle>

                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <v-chip small color="success white--text">{{ data.totalRumah
}}
                                                    </v-chip>
                                                </v-list-item-action>

                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Masyarakat Positif
                                                    </v-list-item-subtitle>

                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <v-chip small color="primary white--text">{{ data.wargaPositif
}}
                                                    </v-chip>
                                                </v-list-item-action>

                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Rumah Positif
                                                    </v-list-item-subtitle>

                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <v-chip small color="primary white--text">{{ data.rumahPositif
}}
                                                    </v-chip>
                                                </v-list-item-action>

                                            </v-list-item>

                                            <v-list-group v-if="data.fasilitasUmum.length > 0" :value="false">
                                                <template v-slot:activator>
                                                    <v-list-item-title>Fasilitas Umum</v-list-item-title>
                                                </template>
                                                <v-list-item>
                                                    <v-list-item-title>
                                                        Nama Fasilitas
                                                    </v-list-item-title>

                                                    <v-list-item-action>
                                                        Jumlah Positif
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-list-item v-for="fasilitasUmum, i in data.fasilitasUmum" :key="i">
                                                    <v-list-item-title> {{ `${fasilitasUmum.fasilitasName}
                                                        (${fasilitasUmum.totalFasilitas})`
}}
                                                    </v-list-item-title>

                                                    <v-list-item-action>
                                                        <v-chip>{{ fasilitasUmum.fasilitasPositif }}</v-chip>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list-group>

                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>
<script>

import { BASE_URL, days, months } from '@/constant'
import service from '../../services/index'


export default {
    props: {
        flush: Object
    },
    name: 'PendataanPuskesmasDetailView',
    data: () => ({
        puskesmas: undefined,
        isLoading: true,
        days,
        months,
    }),
    methods: {
        async getData(puskesmasId, tahun) {
            var query = {
                puskesmasId,
                year: tahun
            }
            var response = await service.getData(BASE_URL + '/pendataan/bypuskesmas', query)

            if (response.status == 200) {
                this.puskesmas = response.data.data

                this.isLoading = false

                this.parsePendataan
            }
        },
    },
    mounted() {
        var puskesmasId = this.$route.params.puskesmasId
        var tahun = this.$route.query.tahun
        this.getData(puskesmasId, tahun)
    },

    computed: {
        parsePendataan() {
            return this.puskesmas.Pendataan.map(value => {
                value['date'] = new Date(value.pemeriksaanDate)
                return value
            })
        }
    }
}
</script>
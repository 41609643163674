<template>
    <v-container class="px-8 py-8">
        <v-row class="pa-4" :justify="'center'">
            <v-col cols="12" md="6">
                <v-card rounded="lg">
                    <v-card-title class="primary white--text">
                        <span class="text-h6">Tambah Puskesmas</span>
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container class="py-5">

                                <v-text-field v-model="nama" label="Nama" placeholder="Puskesmas Tempe" dense outlined
                                    required :rules="namaRules">
                                </v-text-field>

                                <v-text-field v-model="alamat" label="Alamat" placeholder="Alamat Puskesmas" dense
                                    outlined required :rules="alamatRules">
                                </v-text-field>

                                <v-text-field v-model="contact" label="Kontak" placeholder="Kontak yang bisa dihubungi"
                                    dense outlined required :rules="contactRules">
                                </v-text-field>


                                <v-btn @click="submit" color="primary">
                                    Submit
                                </v-btn>

                                <v-dialog v-model="showDialog" width="500">

                                    <v-card rounded="lg">
                                        <v-card-title class="text-h5 primary white--text">
                                            Konfirmasi
                                        </v-card-title>

                                        <v-card-text class="subtitle-1">
                                            <p>Apakah semua data yang dimasukkan telah benar?</p>
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn color="primary" text @click="showDialog = false">
                                                Batal
                                            </v-btn>
                                            <v-btn color="primary" @click="createPuskesmas">
                                                Submit
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>

                    </v-card-actions>
                </v-card>

                <LoadingDialog v-if="onLoading" :show-dialog=onLoading></LoadingDialog>
                <AlertDialog :show-dialog="alertDialog" :message=alertMessage :type=alertType
                    @onchange="onAlertDialogChange">
                </AlertDialog>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import LoadingDialog from '@/components/LoadingDialog.vue';
import AlertDialog from '@/components/AlertDialog.vue';

import service from '../../services/index'
import { BASE_URL } from '@/constant';
import router from '@/router';

export default {
    name: 'PuskesmasCreateView',
    data() {
        return {
            FILE: null,
            valid: true,
            nama: '',
            alamat: '',
            contact: '',
            namaRules: [
                v => !!v || 'Nama harus diisi',
            ],
            alamatRules: [
                v => !!v || 'Alamat harus diisi',
            ],
            contactRules: [

            ],

            showDialog: false,
            onLoading: false,
            alertDialog: false,
            alertMessage: '',
            alertType: "Sukses",
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.showDialog = true
            }
            // this.onLoading = true
        },
        async createPuskesmas() {
            this.showDialog = false
            this.onLoading = true
            var data = {
                nama: this.nama,
                alamat: this.alamat,
                contact: this.contact
            }

            try {
                var response = await service.createData(BASE_URL + '/puskesmas', data)

                if (response.status == 200) {
                    this.alertType = "Sukses"
                    this.alertMessage = "Puskesmas berhasil ditambahkan"
                    this.alertDialog = true

                    this.$refs.form.reset()

                    setTimeout(function () {
                        router.replace({ name: 'puskesmas' })
                    }, 2000)
                }
            } catch (error) {
                console.log('gagal buat users')
                this.alertType = "Gagal"
                this.alertMessage = "Gagal menambahkan puskesmas"
                this.alertDialog = true
            }

            this.onLoading = false
        },
        onAlertDialogChange(e) {
            this.alertDialog = e
        },
        onFileChange(files) {
            console.log('ke trigger bos')
            this.FILE = files
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmPassword || "Password tidak sama";
        }
    },
    components: {
        LoadingDialog,
        AlertDialog
    }
}
</script>
<template>
    <v-container class="px-8 py-8">
        <v-row class="pa-4" :justify="'center'">
            <v-col cols="12" md="6">
                <v-card rounded="lg">
                    <v-card-title class="primary white--text">
                        <span class="text-h6">Update Puskesmas</span>
                        <v-spacer></v-spacer>
                        <v-btn icon color="white" @click="deleteDialog = true">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container class="py-5">

                                <v-text-field v-model="nama" label="Nama" placeholder="Puskesmas Tempe" dense outlined
                                    required :rules="namaRules">
                                </v-text-field>

                                <v-text-field v-model="alamat" label="Alamat" placeholder="Alamat Puskesmas" dense
                                    outlined required :rules="alamatRules">
                                </v-text-field>

                                <v-text-field v-model="contact" label="Kontak" placeholder="Kontak yang bisa dihubungi"
                                    dense outlined required :rules="contactRules">
                                </v-text-field>

                                <v-btn @click="submit" color="primary">
                                    Submit
                                </v-btn>

                                <v-dialog v-model="showDialog" width="500">

                                    <v-card rounded="lg">
                                        <v-card-title class="text-h5 primary white--text">
                                            Konfirmasi
                                        </v-card-title>

                                        <v-card-text class="subtitle-1">
                                            <p>Apakah semua data yang dimasukkan telah benar?</p>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn color="primary" text @click="showDialog = false">
                                                Batal
                                            </v-btn>
                                            <v-btn color="primary" @click="updatepuskesmas">
                                                Submit
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>

                    </v-card-actions>
                </v-card>

                <LoadingDialog v-if="onLoading" :show-dialog=onLoading></LoadingDialog>
                <AlertDialog :show-dialog="alertDialog" :message=alertMessage :type=alertType
                    @onchange="onAlertDialogChange">
                </AlertDialog>

                <v-dialog v-model="deleteDialog" width="500">

                    <v-card rounded="lg">
                        <v-card-title class="text-h5 primary white--text">
                            Konfirmasi
                        </v-card-title>

                        <v-card-text class="subtitle-1">
                            Apakah anda yakin ingin menghapus puskesmas ini?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="primary" text @click="deleteDialog = false">
                                Batal
                            </v-btn>
                            <v-btn color="primary" @click="deletepuskesmas">
                                Oke
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-col>
            <v-col cols="12" md="6">
                <v-card rounded="lg">
                    <v-card-title class="primary white--text">
                        <span class="text-h6">Anggota Desa</span>
                    </v-card-title>

                    <v-card-text>
                        <v-list v-if="puskesmas && puskesmas.anggotaDesa.length > 0">
                            <v-list-item v-for="(desa, i ) in puskesmas.anggotaDesa" :key="i">
                                <v-list-item-avatar>
                                    <v-chip>{{ i + 1 }}</v-chip>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ desa.nama }}
                                    </v-list-item-title>

                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-chip color="primary">{{ desa.kecamatan }}</v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <p v-else>Belum ada anggota desa</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import LoadingDialog from '@/components/LoadingDialog.vue';
import AlertDialog from '@/components/AlertDialog.vue';

import service from '../../services/index'
import { BASE_URL } from '@/constant';
import router from '@/router';

export default {
    name: 'PuskesmasUpdateView',
    data() {
        return {
            puskesmas: null,
            FILE: null,
            valid: true,
            nama: '',
            alamat: '',
            contact: '',
            namaRules: [
                v => !!v || 'Nama harus diisi',
            ],
            alamatRules: [
                v => !!v || 'Alamat harus diisi',
            ],
            contactRules: [

            ],
            showDialog: false,
            onLoading: false,
            alertDialog: false,
            alertMessage: '',
            alertType: "Sukses",
            deleteDialog: false
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.showDialog = true
            }
            // this.onLoading = true
        },
        async updatepuskesmas() {
            this.showDialog = false
            this.onLoading = true
            var data = {
                nama: this.nama,
                alamat: this.alamat,
                contact: this.contact
            }

            try {
                var response = await service.updateData(BASE_URL + '/puskesmas/' + this.$route.params.id, data)

                if (response.status == 200) {
                    this.alertType = "Sukses"
                    this.alertMessage = "Puskesmas berhasil diupate"
                    this.alertDialog = true

                    this.$refs.form.reset()

                    setTimeout(function () {
                        // router.replace({ name: 'puskesmas' })
                        location.reload()
                    }, 2000)
                }
            } catch (error) {
                this.alertType = "Gagal"
                this.alertMessage = "Gagal menambahkan puskesmas"
                this.alertDialog = true
            }

            this.onLoading = false
        },
        async getPuskesmas(id) {
            this.onLoading = true

            try {
                var response = await service.getData(BASE_URL + '/puskesmas/' + id, null)

                if (response.status == 200) {
                    this.puskesmas = response.data.data

                    if (!this.puskesmas) {
                        this.alertType = "Gagal"
                        this.alertMessage = "Puskesmas tidak ditemukan"
                        this.alertDialog = true

                        router.replace({ name: 'puskesmas' })
                    }

                    this.nama = this.puskesmas.nama
                    this.alamat = this.puskesmas.alamat
                    this.contact = this.puskesmas.contact
                }
            } catch (error) {
                this.alertType = "Gagal"
                this.alertMessage = "Terjadi kesalahan dalam memuat puskesmas"
                this.alertDialog = true
            }

            this.onLoading = false
        },

        async deletepuskesmas() {
            try {
                this.deleteDialog = false
                this.onLoading = true
                var response = await service.deleteData(BASE_URL + '/puskesmas/' + this.$route.params.id, null)

                if (response.status == 200) {
                    this.alertType = "Sukses"
                    this.alertMessage = "Puskesmas berhasil dihapus"
                    this.alertDialog = true

                    setTimeout(function () {
                        router.replace({ name: 'puskesmas' })
                    }, 2000)
                }
            } catch (error) {
                console.log(error)
                this.alertType = "Gagal"
                this.alertMessage = "Terjadi kesalahan dalam memuat puskesmas"
                this.alertDialog = true
            }

            this.onLoading = false
        },

        onAlertDialogChange(e) {
            this.alertDialog = e
        },
        onFileChange(files) {
            console.log('ke trigger bos')
            this.FILE = files
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmPassword || "Password tidak sama";
        }
    },
    mounted() {
        var id = this.$route.params.id
        this.getPuskesmas(id)
    },
    components: {
        LoadingDialog,
        AlertDialog
    }
}
</script>
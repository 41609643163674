<template>
    <v-container class="px-8 py-8">
        <v-row class="pa-4" :justify="'center'">
            <v-col cols="12" md="6">
                <v-card rounded="lg">
                    <v-card-title class="primary white--text">
                        <span class="text-h6">Tambah User</span>
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container class="py-5">
                                <v-text-field v-model="email" label="Email Address" placeholder="petugas@mail.com" dense
                                    outlined prepend-inner-icon="mdi-email" required :rules="emailRules">
                                </v-text-field>
                                <v-text-field v-model="name" label=" Name" placeholder="Nama" :rules="nameRules" dense
                                    outlined prepend-inner-icon="mdi-account"></v-text-field>
                                <v-text-field v-model="password" label="Password" placeholder="Password"
                                    :rules="passwordRules" dense outlined type="password" prepend-inner-icon="mdi-lock">
                                </v-text-field>
                                <v-text-field v-model="confirmPassword" label="Confirm Password"
                                    placeholder="Confirm Password"
                                    :rules="confirmPasswordRules.concat(passwordConfirmationRule)" dense outlined
                                    type="password" prepend-inner-icon="mdi-lock-check">
                                </v-text-field>
                                <v-text-field v-model="phone" label="Nomor HP" placeholder="Nomor HP" dense outlined
                                    :rules="phoneRules" type="number" prepend-inner-icon="mdi-phone">
                                </v-text-field>

                                <v-select v-model="role" :items="roles" label="Roles" outlined :rules="roleRules"
                                    prepend-inner-icon="mdi-offer">
                                </v-select>

                                <v-btn @click="submit" color="primary">
                                    Submit
                                </v-btn>

                                <v-dialog v-model="showDialog" width="500">

                                    <v-card rounded="lg">
                                        <v-card-title class="text-h5 primary white--text">
                                            Konfirmasi
                                        </v-card-title>

                                        <v-card-text class="subtitle-1">
                                            <p>Apakah semua data yang dimasukkan telah benar?</p>
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn color="primary" text @click="showDialog = false">
                                                Batal
                                            </v-btn>
                                            <v-btn color="primary" @click="createUser">
                                                Submit
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>

                    </v-card-actions>
                </v-card>

                <LoadingDialog v-if="onLoading" :show-dialog=onLoading></LoadingDialog>
                <AlertDialog :show-dialog="alertDialog" :message=alertMessage :type=alertType
                    @onchange="onAlertDialogChange">
                </AlertDialog>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import LoadingDialog from '@/components/LoadingDialog.vue';
import AlertDialog from '@/components/AlertDialog.vue';

import service from '../../services/index'
import { BASE_URL } from '@/constant';
import router from '@/router';

export default {
    name: 'UsersCreateView',
    data: () => ({
        valid: true,
        roles: [
            {
                text: 'Petugas',
                value: 'Petugas'
            },
            {
                text: 'Admin',
                value: 'Admin'
            },
        ],
        email: '',
        name: '',
        password: '',
        confirmPassword: '',
        role: null,
        phone: '',
        nameRules: [
            v => !!v || 'Nama harus diisi',
            v => (v && v.length > 4) || 'Nama harus lebih dari 4 karakter',
        ],
        emailRules: [
            v => !!v || 'Email harus diisi',
            v => /.+@.+\..+/.test(v) || 'Email tidak valid',
        ],
        passwordRules: [
            v => !!v || 'Password harus diisi',
            v => (v && v.length >= 6) || 'Password minimal 6 karakter'
        ],
        confirmPasswordRules: [
            v => !!v || 'Password harus diisi',
        ],
        phoneRules: [
            v => !!v || 'Nomor telepon harus diisi',
            v => (v && v.length >= 9 && v.length <= 13) || 'Nomor telepon minimal 9 karakter dan maksimal 13 karakter'
        ],
        roleRules: [
            v => !!v || 'Role harus dipilih'
        ],
        showDialog: false,
        onLoading: false,
        alertDialog: false,
        alertMessage: '',
        alertType: "Sukses",
    }),
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.showDialog = true
            }
            // this.onLoading = true
        },
        async createUser() {
            this.showDialog = false
            this.onLoading = true
            var data = {
                name: this.name,
                email: this.email,
                password: this.password,
                role: this.role,
                phone: this.phone
            }

            try {
                var response = await service.createData(BASE_URL + '/users/create', data)

                if (response.status == 200) {
                    this.alertType = "Sukses"
                    this.alertMessage = "User berhasil ditambahkan"
                    this.alertDialog = true

                    this.$refs.form.reset()

                    setTimeout(function () {
                        router.replace({ name: 'users' })
                    }, 1000)
                }
            } catch (error) {
                console.log('gagal buat users')
                this.alertType = "Gagal"
                this.alertMessage = "Gagal menambahkan user"
                this.alertDialog = true
            }

            this.onLoading = false
        },
        onAlertDialogChange(e) {
            this.alertDialog = e
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmPassword || "Password tidak sama";
        }
    },
    components: {
        LoadingDialog,
        AlertDialog
    }
}
</script>
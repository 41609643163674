import axios from 'axios'


async function getData(url, query) {

    var token = localStorage.getItem('token')

    try {
        var response = await axios({
            method: 'GET',
            url,
            params: query,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })

        return response
    } catch (error) {
        if (error.response.status == 401 || error.response.status == 403) {
            location.reload()
        } else {
            throw new Error(error)
        }
    }

}

async function createData(url, data) {


    var token = localStorage.getItem('token')

    try {
        var response = await axios({
            method: 'POST',
            url,
            data,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })

        return response
    } catch (error) {
        if (error.response.status == 401 || error.response.status == 403) {
            location.reload()
        } else {
            throw new Error(error)
        }
    }
}

async function updateData(url, data) {
    var response = await axios({
        method: 'PUT',
        url,
        data
    })

    return response
}

async function deleteData(url, data) {
    var response = await axios({
        method: 'DELETE',
        url,
        data
    })

    return response
}

export default {
    getData: getData,
    createData: createData,
    updateData: updateData,
    deleteData: deleteData
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import PendataanView from '../views/pendataan/Index.vue'
import PendataanDetailView from '../views/pendataan/Detail.vue'
import UsersView from '../views/users/Index.vue'
import UsersCreateView from '../views/users/Create.vue'
import LaporanMasyarakatView from '../views/laporan-masyarakat/Index.vue'
import LaporanMasyarakatDetailView from '../views/laporan-masyarakat/Detail.vue'
import ArticleView from '../views/articles/Index'
import ArticleCreateView from '../views/articles/Create'
import ArticleUpdateView from '../views/articles/Update'
import PemetaanView from '../views/pemetaan/Index'
import DashboardView from '../views/dashboard/Index'
import DesaView from '../views/desa/Index'
import DesaUpdateView from '../views/desa/Update'
import LaporanJentikView from '../views/laporan-jentik/Index.vue'
import LaporanJentikDetailView from '../views/laporan-jentik/Detail.vue'
import NotificationView from '../views/notifications/Index.vue'
import PuskesmasView from '../views/puskesmas/Index.vue'
import PuskesmasCreateView from '../views/puskesmas/Create.vue'
import PuskesmasUpdateView from '../views/puskesmas/Update.vue'
import PendataanPuskesmasView from '../views/pendataan-puskesmas/Index.vue'
import PendataanPuskesmasDetailView from '../views/pendataan-puskesmas/Detail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard-null',
    component: DashboardView,
    meta: {
      reload: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/pendataan',
    name: 'pendataan',
    component: PendataanView
  },
  {
    path: '/pendataan/detail/:desaId',
    name: 'pendataan-detail',
    component: PendataanDetailView
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: UsersCreateView
  },

  //laporan masyarakat

  {
    path: '/laporan-masyarakat',
    name: 'laporan-masyarakat',
    component: LaporanMasyarakatView
  },
  {
    path: '/laporan-masyarakat/detail/:laporanId',
    name: 'laporan-masyarakat-detail',
    component: LaporanMasyarakatDetailView,
    meta: {
      reload: true
    }
  },

  //laporan jentik

  {
    path: '/laporan-jentik',
    name: 'laporan-jentik',
    component: LaporanJentikView
  },
  {
    path: '/laporan-jentik/detail/:laporanId',
    name: 'laporan-jentik-detail',
    component: LaporanJentikDetailView
  },

  //articles

  {
    path: '/articles',
    name: 'articles',
    component: ArticleView
  },
  {
    path: '/articles/create',
    name: 'articles-create',
    component: ArticleCreateView
  },
  {
    path: '/articles/update/:id',
    name: 'articles-update',
    component: ArticleUpdateView
  },

  //pemetaan
  {
    path: '/pemetaan',
    name: 'pemetaan',
    component: PemetaanView
  },

  //desa

  {
    path: '/desa',
    name: 'desa',
    component: DesaView
  },

  {
    path: '/desa/:id',
    name: 'desa-update',
    component: DesaUpdateView
  },

  //notifikasi
  {
    path: '/notification',
    name: 'notification',
    component: NotificationView,
    meta: {
      reload: true
    }
  },

  //puskesmas
  {
    path: '/puskesmas',
    name: 'puskesmas',
    component: PuskesmasView,
    meta: {
      reload: true
    }
  },
  {
    path: '/puskesmas/create',
    name: 'puskesmas-create',
    component: PuskesmasCreateView,
    meta: {
      reload: true
    }
  },
  {
    path: '/puskesmas/update/:id',
    name: 'puskesmas-update',
    component: PuskesmasUpdateView
  },

  //pendataan puskesmas
  {
    path: '/pendataan-puskesmas',
    name: 'penpus',
    component: PendataanPuskesmasView
  },
  {
    path: '/pendataan-puskesmas/detail/:puskesmasId',
    name: 'penpus-detail',
    component: PendataanPuskesmasDetailView
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <v-container class="px-8 py-8">
    <v-row class="pa-4">
      <div class="text text-h5 font-weight-bold">Daftar Desa</div>
    </v-row>
    <v-row>
      <!-- <v-col md="3" cols="12" :align="'left'">
        <v-btn color="primary" @click="addDesa">
          <v-icon left> mdi-plus-circle </v-icon>

          Tambah
        </v-btn>
      </v-col> -->
      <!-- <v-spacer></v-spacer> -->
      <v-col md="3" cols="12">
        <v-text-field
          label="Cari"
          @input="onSearchChange"
          dense
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pa-4 rounded-lg">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    No
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Nama
                  </th>
                  <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Kecamatan
                  </th>
                  <!-- <th
                    class="text-left subtitle-1 font-weight-bold primary--text"
                  >
                    Jumlah Rumah
                  </th> -->
                  <th
                    class="
                      text-center
                      subtitle-1
                      font-weight-bold
                      primary--text
                    "
                  >
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in desa" :key="item.id">
                  <td class="text-left">
                    {{ i + 1 + (currentPage - 1) * dataPerPage }}
                  </td>
                  <td class="text-left">{{ item.nama }}</td>
                  <td class="text-left">{{ item.kecamatan }}</td>
                  <!-- <td class="text-left">{{ item.jumlahRumah }}</td> -->
                  <td class="text-center">
                    <v-btn icon @click="goDetail(item.id)">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row :justify="'center'">
      <div v-if="currentPage == 1">
        <v-btn icon @click="onPrevPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon class="primary white--text">
          {{ currentPage }}
        </v-btn>
        <v-btn
          v-if="currentPage + 2 <= totalPage"
          icon
          @click="gotoPage(currentPage + 1)"
        >
          {{ currentPage + 1 }}
        </v-btn>
        <v-btn
          v-if="currentPage + 2 <= totalPage"
          icon
          @click="gotoPage(currentPage + 2)"
        >
          {{ currentPage + 2 }}
        </v-btn>
        <v-btn icon>
          <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <div v-else-if="currentPage == totalPage">
        <v-btn icon @click="onPrevPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          v-if="currentPage - 2 > 0"
          icon
          @click="gotoPage(currentPage - 2)"
        >
          {{ currentPage - 2 }}
        </v-btn>
        <v-btn icon @click="gotoPage(currentPage - 1)">
          {{ currentPage - 1 }}
        </v-btn>
        <v-btn icon class="primary white--text">
          {{ currentPage }}
        </v-btn>
        <v-btn icon>
          <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
        </v-btn>
      </div>

      <div v-else>
        <v-btn icon @click="onPrevPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon @click="gotoPage(currentPage - 1)">
          {{ currentPage - 1 }}
        </v-btn>
        <v-btn icon class="primary white--text">
          {{ currentPage }}
        </v-btn>
        <v-btn icon @click="gotoPage(currentPage + 1)">
          {{ currentPage + 1 }}
        </v-btn>
        <v-btn icon>
          <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-dialog v-model="preview" scrollable max-width="500px">
      <v-card v-if="desa.length > 0">
        <v-card-title>
          <span class="text-h6 text-justify">{{
            desa[selectedPreview].title
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-img :src="desa[selectedPreview].photoUrl"></v-img>
          <p class="text-justify">{{ desa[selectedPreview].content }}</p>

          <p class="text-justify font-weight-bold">
            Author : {{ desa[selectedPreview].author }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import service from "../../services/index.js";

import { BASE_URL, months } from "../../constant";
import router from "@/router";

export default {
  name: "DesaView",
  data() {
    return {
      desa: [],
      years: [],
      selectedYear: undefined,
      currentYear: 2022,
      currentPage: 1,
      totalPage: 0,
      totalData: 0,
      dataPerPage: 0,
      search: "",
      flushType: undefined,
      flushMessage: undefined,
      preview: false,
      selectedPreview: 0,
    };
  },
  methods: {
    async getDesa(currentPage) {
      var query = {
        currentPage,
        search: this.search,
      };

      var response = await service.getData(BASE_URL + "/desa", query);

      if (response.status == 200) {
        this.currentIndex = currentPage;
        this.desa = response.data.data;
        this.dataPerPage = response.data.metadata.dataPerPage;
        this.totalPage = response.data.metadata.totalPage;
      }
    },

    async onNextPage() {
      if (this.currentPage >= this.totalPage) {
        return;
      }
      this.getDesa(++this.currentPage);
    },

    async onPrevPage() {
      if (1 != this.currentPage) {
        this.getDesa(--this.currentPage);
      }
    },

    async gotoPage(page) {
      if (!(page > this.totalPage) || !(page < 1)) {
        this.currentPage = page;
        this.getDesa(this.currentPage);
      }
    },

    async onSearch(value) {
      this.search = value;
      this.currentPage = 1;
      this.getDesa(this.currentPage, value);
    },

    debounce(func, delay) {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    },

    async onSearchChange(value) {
      this.debounce(this.onSearch(value), 1000);
    },

    async addDesa() {
      router.replace({
        name: "desa-update",
      });
    },
    showPreview(index) {
      this.selectedPreview = index;
      this.preview = true;
    },
    goDetail(index) {
      router.replace({
        name: "desa-update",
        params: {
          id: index,
        },
      });
    },
  },
  mounted() {
    this.getDesa(this.currentPage);
  },
  computed: {
    formatDateInput() {
      return this.desa.map((article) => {
        var date = new Date(article.createdAt);
        article["dateFormat"] = `${date.getDate()} ${
          months[date.getMonth()]
        } ${date.getFullYear()}`;

        return article;
      });
    },
  },
};
</script>
  
<template>
  <v-container class="px-8 py-8">
    <v-row class="pa-4" :justify="'center'">
      <v-col cols="12" md="6">
        <v-card rounded="lg">
          <v-card-title class="primary white--text">
            <span class="text-h6">Update Desa</span>
            <!-- <v-spacer></v-spacer>
            <v-btn icon color="white" @click="deleteDialog = true">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container class="py-5">
                <v-text-field v-model="nama" label="Nama Desa" placeholder="Kelurahan Tempe" dense outlined required
                  :rules="namaRules">
                </v-text-field>

                <v-text-field v-model="kecamatan" label="Kecamatan" placeholder="Kecamatan Tempe" dense outlined
                  required :rules="kecamatanRules">
                </v-text-field>

                <v-text-field v-model="latitude" label="Latitude" placeholder="-0.6747346" dense outlined required
                  :rules="latitudeRules">
                </v-text-field>

                <v-text-field v-model="longitude" label="Longitude" placeholder="-0.6747346" dense outlined required
                  :rules="longitudeRules">
                </v-text-field>

                <v-text-field v-model="jumlahRumah" label="Jumlah Rumah" placeholder="1000" type="number" dense outlined
                  required :rules="rumahRules">
                </v-text-field>

                <v-text-field v-model="jumlahWarga" label="Jumlah Warga" placeholder="10000" type="number" dense
                  outlined required :rules="wargaRules">
                </v-text-field>

                <v-select :items="listPuskesmas" v-model="puskesmas" dense outlined>

                </v-select>

                <v-btn @click="submit" color="primary"> Simpan </v-btn>

                <v-dialog v-model="showDialog" width="500">
                  <v-card rounded="lg">
                    <v-card-title class="text-h5 primary white--text">
                      Konfirmasi
                    </v-card-title>

                    <v-card-text class="subtitle-1">
                      <p>Apakah semua data yang dimasukkan telah benar?</p>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn color="primary" text @click="showDialog = false">
                        Batal
                      </v-btn>
                      <v-btn color="primary" @click="updateDesa">
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>

        <LoadingDialog v-if="onLoading" :show-dialog="onLoading"></LoadingDialog>
        <AlertDialog :show-dialog="alertDialog" :message="alertMessage" :type="alertType"
          @onchange="onAlertDialogChange">
        </AlertDialog>

        <v-dialog v-model="deleteDialog" width="500">
          <v-card rounded="lg">
            <v-card-title class="text-h5 primary white--text">
              Konfirmasi
            </v-card-title>

            <v-card-text class="subtitle-1">
              Apakah anda yakin ingin menghapus desa ini?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="deleteDialog = false">
                Batal
              </v-btn>
              <v-btn color="primary" @click="deleteDesa"> Oke </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import AlertDialog from "@/components/AlertDialog.vue";

import service from "../../services/index";
import { BASE_URL } from "@/constant";
import router from "@/router";

export default {
  name: "DesaUpdateView",
  data() {
    return {
      desa: null,
      FILE: null,
      valid: true,
      nama: "",
      kecamatan: "",
      latitude: "",
      longitude: "",
      jumlahRumah: "",
      jumlahWarga: "",
      namaRules: [(v) => !!v || "Nama desa harus diisi"],
      kecamatanRules: [(v) => !!v || "Kecamatan harus diisi"],
      longitudeRules: [(v) => !!v || "Longitude harus diisi"],
      latitudeRules: [(v) => !!v || "Latitude harus diisi"],
      rumahRules: [(v) => !!v || "Jumlah rumah harus diisi"],
      wargaRules: [(v) => !!v || "Jumlah warga harus diisi"],
      showDialog: false,
      onLoading: false,
      alertDialog: false,
      alertMessage: "",
      alertType: "Sukses",
      deleteDialog: false,
      listPuskesmas: [],
      puskesmas: null
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.showDialog = true;
      }
      // this.onLoading = true
    },
    async updateDesa() {
      this.showDialog = false;
      this.onLoading = true;
      var data = {
        nama: this.nama,
        kecamatan: this.kecamatan,
        latitude: this.latitude,
        longitude: this.longitude,
        jumlahRumah: this.jumlahRumah,
        jumlahWarga: this.jumlahWarga,
        puskesmasId: this.puskesmas
      };

      try {
        var response = await service.updateData(
          BASE_URL + "/desa/update/" + this.$route.params.id,
          data
        );

        if (response.status == 200) {
          this.alertType = "Sukses";
          this.alertMessage = "Desa berhasil diupate";
          this.alertDialog = true;

          this.$refs.form.reset();

          setTimeout(function () {
            router.replace({ name: "desa" });
          }, 2000);
        }
      } catch (error) {
        this.alertType = "Gagal";
        this.alertMessage = "Gagal menambahkan desa";
        this.alertDialog = true;
      }

      this.onLoading = false;
    },
    async getDesa(id) {
      this.onLoading = true;

      try {
        var response = await service.getData(
          BASE_URL + "/desa/detail/" + id,
          null
        );

        if (response.status == 200) {
          this.desa = response.data.data;

          if (!this.desa) {
            this.alertType = "Gagal";
            this.alertMessage = "Desa tidak ditemukan";
            this.alertDialog = true;

            router.replace({ name: "desas" });
          }

          this.nama = this.desa.nama;
          this.kecamatan = this.desa.kecamatan;
          this.latitude = this.desa.latitude;
          this.longitude = this.desa.longitude;
          this.jumlahRumah = this.desa.jumlahRumah;
          this.jumlahWarga = this.desa.jumlahWarga;

          if (this.listPuskesmas.length > 0 && this.desa.puskesmasId) {
            this.puskesmas = this.listPuskesmas.find(p => {
              return p.value == this.desa.puskesmasId
            }).value
          }
        }
      } catch (error) {
        console.log(error)
        this.alertType = "Gagal";
        this.alertMessage = "Terjadi kesalahan dalam memuat desa";
        this.alertDialog = true;
      }

      this.onLoading = false;
    },

    async deleteDesa() {
      try {
        this.deleteDialog = false;
        this.onLoading = true;
        var response = await service.deleteData(
          BASE_URL + "/desas/" + this.$route.params.id,
          null
        );

        if (response.status == 200) {
          this.alertType = "Sukses";
          this.alertMessage = "Desa berhasil dihapus";
          this.alertDialog = true;

          setTimeout(function () {
            router.replace({ name: "desa" });
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.alertType = "Gagal";
        this.alertMessage = "Terjadi kesalahan dalam memuat detail desa";
        this.alertDialog = true;
      }

      this.onLoading = false;
    },

    onAlertDialogChange(e) {
      this.alertDialog = e;
    },
    onFileChange(files) {
      console.log("ke trigger bos");
      this.FILE = files;
    },
    async getPuskesmas() {
      var response = await service.getData(BASE_URL + '/puskesmas', {
        pageSize: 1000
      })

      if (response.status == 200) {
        this.listPuskesmas = response.data.data.map(p => {
          return {
            text: p.nama,
            value: p.id
          }
        })
      }
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "Password tidak sama";
    },
  },
  async mounted() {
    var id = this.$route.params.id;
    await this.getPuskesmas()
    this.getDesa(id);
  },
  components: {
    LoadingDialog,
    AlertDialog,
  },
};
</script>
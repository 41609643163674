<template>
    <v-app>
        <v-row fluid fill-height>
            <v-col cols="9" class="d-none d-md-flex">
                <v-img src="@/assets/images/bglogin.jpg">
                </v-img>
            </v-col>
            <v-col cols="12" md="3">
                <v-container fluid fill-height :align="'center'" class="px-5 py-0">
                    <v-row>
                        <v-img src="@/assets/images/logo.png" contain max-height="150"></v-img>
                        <v-col cols="12">
                            <p class="text-center">Selamat Datang Admin!</p>
                            <v-form ref="login" @submit.prevent="login">
                                <v-text-field v-model="email" label="email" type="email" :rules="emailRules" outlined
                                    dense>
                                </v-text-field>
                                <v-text-field v-model="password" label="password" type="password" :rules="passwordRules"
                                    outlined dense>
                                </v-text-field>
                                <v-btn block color="primary" type="submit">
                                    Login
                                </v-btn>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <LoadingDialog v-if="onLoading" :show-dialog=onLoading></LoadingDialog>
        <AlertDialog :show-dialog="alertDialog" :message=alertMessage :type=alertType @onchange="onAlertDialogChange">
        </AlertDialog>
    </v-app>
</template>
<script>

import service from '../../services/index'
import LoadingDialog from '@/components/LoadingDialog.vue';
import AlertDialog from '@/components/AlertDialog.vue';
import { BASE_URL } from '@/constant';
import router from '@/router';

export default {
    name: 'LoginPageView',
    data() {
        return {
            alignments: [
                'start',
                'center',
                'end',
            ],
            email: '',
            password: '',
            emailRules: [
                v => !!v || 'Email harus diisi',
                v => /.+@.+\..+/.test(v) || 'Email tidak valid',
            ],
            passwordRules: [
                v => !!v || 'Password harus diisi',
                v => (v && v.length >= 6) || 'Password minimal 6 karakter'
            ],
            onLoading: false,
            alertDialog: false,
            alertType: 'Gagal',
            alertMessage: 'Username atau Password Salah'
        }
    },
    methods: {
        async login() {
            if (this.$refs.login.validate()) {
                this.onLoading = true

                var body = {
                    email: this.email,
                    password: this.password
                }

                try {
                    var response = await service.createData(BASE_URL + '/users/login-admin', body)

                    // localStorage.setItem('isLogin', true)
                    // localStorage.setItem('user', JSON.stringify(response.data.data))
                    // localStorage.setItem('token', response.data.data.token)

                    localStorage.isLogin = true
                    localStorage.user = JSON.stringify(response.data.data)
                    localStorage.token = response.data.data.token

                    // eslint-disable-next-line
                    router.replace({
                        name: 'dashboard'
                    })


                    router.go()
                } catch (error) {
                    this.alertDialog = true
                }

                this.onLoading = false
            }
        },
        onAlertDialogChange(e) {
            this.alertDialog = e
        },
        onKeyPassDown() {
            // if (e.key === 'Enter') {
            //     this.login()
            // }
        }
    },
    components: {
        LoadingDialog,
        AlertDialog
    }
}
</script>
<template>
  <div id="app">
    <template v-if="isLogin">
      <MainPageView> </MainPageView>
    </template>
    <template v-else-if="!isLogin && isReady">
      <LoginView></LoginView>
    </template>
  </div>
</template>
<script>
import MainPageView from "./views/main/MainPage.vue";
import LoginView from "./views/main/LoginPage.vue";

import service from "./services/index";
import { BASE_URL } from "./constant";
export default {
  components: {
    MainPageView,
    LoginView,
  },
  data() {
    return {
      isLogin: false,
      isReady: false,
    };
  },
  methods: {
    async validateUser() {
      var login = localStorage.isLogin;

      login = login === "true";

      if (!login) {
        this.isLogin = false;
      } else {
        try {
          var response = await service.getData(
            BASE_URL + "/users/validate-admin"
          );

          if (response.status == 200) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            this.isLogin = true;
          }
        } catch (error) {
          localStorage.setItem("isLogin", false);
          localStorage.removeItem("user");
          localStorage.removeItem("token");

          // location.reload()
        }
      }

      this.isReady = true;
    },
  },
  mounted() {
    this.validateUser();
  },
  updated() {
    this.validateUser();
  },
  watch: {
    $route(_, __) {
      _;
      __;
      this.validateUser();
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
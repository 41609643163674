<template>
    <v-container class="px-8 py-8">
        <v-row class="pa-4">
            <div class="text text-h5 font-weight-bold">Daftar Users</div>
        </v-row>
        <v-row>
            <v-col md="3" cols="12" :align="'left'">
                <v-btn color="primary" @click="addUser">
                    <v-icon left>
                        mdi-plus-circle
                    </v-icon>

                    Tambah
                </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col md="3" cols="12">
                <v-text-field label="Cari" @input="onSearchChange" dense outlined></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="pa-4 rounded-lg">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left text-h6 primary--text">
                                        No
                                    </th>
                                    <th class="text-left text-h6 primary--text">
                                        Nama
                                    </th>
                                    <th class="text-left text-h6 primary--text">
                                        Email
                                    </th>
                                    <th class="text-left text-h6 primary--text">
                                        Role
                                    </th>
                                    <th class="text-left text-h6 primary--text">
                                        No HP
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item, i in users" :key="item.id">
                                    <td class="text-left">{{ ++i + ((currentPage - 1) * dataPerPage) }}</td>
                                    <td class="text-left">{{ item.nama }}</td>
                                    <td class="text-left">{{ item.email }}</td>
                                    <td class="text-left">{{ item.role }}</td>
                                    <td class="text-left">{{ item.phoneNumber }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>

        <v-row :justify="'center'">

            <div v-if="currentPage == 1">
                <v-btn icon @click="onPrevPage">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn icon class="primary white--text">
                    {{ currentPage }}
                </v-btn>
                <v-btn v-if="currentPage + 2 <= totalPage" icon @click="gotoPage(currentPage + 1)">
                    {{ currentPage + 1 }}
                </v-btn>
                <v-btn v-if="currentPage + 2 <= totalPage" icon @click="gotoPage(currentPage + 2)">
                    {{ currentPage + 2 }}
                </v-btn>
                <v-btn icon>
                    <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
                </v-btn>
            </div>

            <div v-else-if="currentPage == totalPage">
                <v-btn icon @click="onPrevPage">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn v-if="currentPage - 2 > 0" icon @click="gotoPage(currentPage - 2)">
                    {{ currentPage - 2 }}
                </v-btn>
                <v-btn icon @click="gotoPage(currentPage - 1)">
                    {{ currentPage - 1 }}
                </v-btn>
                <v-btn icon class="primary white--text">
                    {{ currentPage }}
                </v-btn>
                <v-btn icon>
                    <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
                </v-btn>
            </div>

            <div v-else>
                <v-btn icon @click="onPrevPage">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn icon @click="gotoPage(currentPage - 1)">
                    {{ currentPage - 1 }}
                </v-btn>
                <v-btn icon class="primary white--text">
                    {{ currentPage }}
                </v-btn>
                <v-btn icon @click="gotoPage(currentPage + 1)">
                    {{ currentPage + 1 }}
                </v-btn>
                <v-btn icon>
                    <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
                </v-btn>
            </div>
        </v-row>

    </v-container>
</template>
  
<script>

import router from '@/router'

import service from '../../services/index.js'

import { BASE_URL } from '../../constant'

export default {
    props: ['flush'],
    name: 'UsersView',
    data() {
        return {
            users: [],
            years: [],
            selectedYear: undefined,
            currentYear: 2022,
            currentPage: 1,
            totalPage: 0,
            totalData: 0,
            dataPerPage: 0,
            search: "",
            flushType: undefined,
            flushMessage: undefined
        }
    },
    methods: {
        async getUsers(currentPage) {

            var query = {
                currentPage,
                search: this.search
            }

            var response = await service.getData(BASE_URL + '/users', query)

            if (response.status == 200) {
                this.currentIndex = currentPage
                this.users = response.data.data
                this.dataPerPage = response.data.metadata.dataPerPage
                this.totalPage = response.data.metadata.totalPage
            }


        },

        async onNextPage() {
            if (this.currentPage >= this.totalPage) {
                return
            }
            this.getUsers(++this.currentPage)
        },

        async onPrevPage() {
            if (1 != this.currentPage) {
                this.getUsers(--this.currentPage)
            }
        },

        async gotoPage(page) {
            if (!(page > this.totalPage) || !(page < 1)) {
                this.currentPage = page
                this.getUsers(this.currentPage)
            }
        },

        async addUser() {
            router.push({
                name: 'users-create'
            })
        },

        async onSearch(value) {
            this.search = value
            this.currentPage = 1
            this.getUsers(this.currentPage, value)
        },

        debounce(func, delay) {
            let debounceTimer
            return function () {
                const context = this
                const args = arguments
                clearTimeout(debounceTimer)
                debounceTimer
                    = setTimeout(() => func.apply(context, args), delay)
            }
        },

        async onSearchChange(value) {
            this.debounce(this.onSearch(value), 1000)
        }
    },
    mounted() {
        this.getUsers(this.currentPage)
        if (this.flush) {
            console.log('hey jude')
            console.log(this.flush.message)
            this.flushType = this.flush.type
            this.flushMessage = this.flush.message
        }
    }
}
</script>
  
<template>
    <v-container class="px-8 py-8">
        <v-row class="pa-4">
            <div class="text text-h5 font-weight-bold">Daftar Pendataan Berdasarkan Puskesmas</div>
        </v-row>
        <v-row>
            <v-col md="2" xs="12">
                <v-select :items="years" label="Tahun" v-model="selectedYear" v-on:change="onSelectedYearChange" dense
                    outlined>
                </v-select>

            </v-col>
            <v-spacer></v-spacer>
            <v-col md="3" xs="12">
                <v-text-field label="Cari" @input="onSearchChange" dense outlined></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="pa-4 rounded-lg">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left text-h6 primary--text">
                                        No
                                    </th>
                                    <th class="text-left text-h6 primary--text">
                                        Nama Puskesmas
                                    </th>
                                    <th class="text-left text-h6 primary--text">
                                        Alamat
                                    </th>
                                    <th class="text-left text-h6 primary--text">
                                        Kontak
                                    </th>
                                    <th class="text-right text-h6 primary--text">
                                        Aksi
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item, i in puskesmas" :key="item.id">
                                    <td class="text-left">{{ ++i + ((currentPage - 1) * dataPerPage) }}</td>
                                    <td class="text-left">{{ item.nama }}</td>
                                    <td class="text-left">{{ item.alamat }}</td>
                                    <td class="text-left">{{ item.contact }}</td>
                                    <!-- <td class="text-center">
                                        <v-chip>
                                            {{ item.abj.toFixed(2) }}
                                        </v-chip>
                                    </td> -->
                                    <td class="text-right">
                                        <v-btn icon color="primary" @click="toDetail(item.id)" dark>
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>

        <v-row :justify="'center'">

            <div v-if="currentPage == 1">
                <v-btn icon @click="onPrevPage">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn icon class="primary white--text">
                    {{ currentPage }}
                </v-btn>
                <v-btn v-if="currentPage + 2 <= totalPage" icon @click="gotoPage(currentPage + 1)">
                    {{ currentPage + 1 }}
                </v-btn>
                <v-btn v-if="currentPage + 2 <= totalPage" icon @click="gotoPage(currentPage + 2)">
                    {{ currentPage + 2 }}
                </v-btn>
                <v-btn icon>
                    <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
                </v-btn>
            </div>

            <div v-else-if="currentPage == totalPage">
                <v-btn icon @click="onPrevPage">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn v-if="currentPage - 2 > 0" icon @click="gotoPage(currentPage - 2)">
                    {{ currentPage - 2 }}
                </v-btn>
                <v-btn icon @click="gotoPage(currentPage - 1)">
                    {{ currentPage - 1 }}
                </v-btn>
                <v-btn icon class="primary white--text">
                    {{ currentPage }}
                </v-btn>
                <v-btn icon>
                    <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
                </v-btn>
            </div>

            <div v-else>
                <v-btn icon @click="onPrevPage">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn icon @click="gotoPage(currentPage - 1)">
                    {{ currentPage - 1 }}
                </v-btn>
                <v-btn icon class="primary white--text">
                    {{ currentPage }}
                </v-btn>
                <v-btn icon @click="gotoPage(currentPage + 1)">
                    {{ currentPage + 1 }}
                </v-btn>
                <v-btn icon>
                    <v-icon @click="onNextPage">mdi-arrow-right</v-icon>
                </v-btn>
            </div>
        </v-row>

    </v-container>
</template>
  
<script>

import { BASE_URL } from '@/constant'
import router from '@/router'
import service from '../../services/index'

export default {
    name: 'PendataanPuskesmasView',
    data: () => ({
        puskesmas: [],
        years: [],
        selectedYear: undefined,
        currentYear: 2022,
        currentPage: 1,
        totalPage: 0,
        totalData: 0,
        dataPerPage: 0,
        search: ""
    }),
    methods: {
        async getPendataan(tahun, currentPage) {
            var query = {
                currentPage,
                search: this.search,
                pageSize: 15
            }

            var pendataan = await service.getData(BASE_URL + '/puskesmas', query)

            this.currentIndex = currentPage
            this.puskesmas = pendataan.data.data
            this.dataPerPage = pendataan.data.metadata.dataPerPage
            this.totalPage = pendataan.data.metadata.totalPage
        },
        async getYears() {
            var response = await service.getData(BASE_URL + '/pendataan/years')

            var years = response.data.data
            if (years) {
                this.years = years.map((year) => {

                    return {
                        text: `${year.year}`,
                        value: year.year
                    }
                })
            }

            this.selectedYear = this.years[this.years.length - 1]
        },

        async onSelectedYearChange(value) {
            this.currentYear = value
            this.currentPage = 1
            this.getPendataan(this.currentYear, this.currentPage)
        },

        async onNextPage() {
            if (this.currentPage >= this.totalPage) {
                return
            }
            this.getPendataan(this.currentYear, ++this.currentPage)
        },

        async onPrevPage() {
            if (1 != this.currentPage) {
                this.getPendataan(this.currentYear, --this.currentPage)
            }
        },

        async gotoPage(page) {
            if (!(page > this.totalPage) || !(page < 1)) {
                this.currentPage = page
                this.getPendataan(this.currentYear, this.currentPage)
            }
        },

        async toDetail(puskesmasId) {
            router.push({
                name: 'penpus-detail',
                params: {
                    puskesmasId
                },
                query: {
                    tahun: this.currentYear
                }
            })
        },

        async onSearch(value) {
            this.search = value
            this.currentPage = 1
            this.getPendataan(this.currentYear, this.currentPage)
        },

        debounce(func, delay) {
            let debounceTimer
            return function () {
                const context = this
                const args = arguments
                clearTimeout(debounceTimer)
                debounceTimer
                    = setTimeout(() => func.apply(context, args), delay)
            }
        },

        async onSearchChange(value) {
            this.debounce(this.onSearch(value), 1000)
        }
    },
    mounted() {
        this.getPendataan(this.currentYear, this.currentPage)
        this.getYears()
    }
}
</script>
  
<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" class="text-left">
      <v-list>
        <v-list-item>
          <v-img src="@/assets/images/logo.png" max-height="100" contain></v-img>
        </v-list-item>

        <!-- <v-list-item link :three-line="true">
          <v-list-item-content>
            <v-list-item-title class="title primary--text">
              GoCantik
            </v-list-item-title>
            <v-list-item-subtitle>Dinas Kesehatan Kabupaten Wajo</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <v-divider></v-divider>
      <v-list nav shaped dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="(item, i) in items" :key="i" @click="navigateTo(items[i].name)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-list nav shaped dense>
        <v-list-group :value="false" prepend-icon="mdi-dns" color="primary">
          <template v-slot:activator>
            <v-list-item-title>Master</v-list-item-title>
          </template>
          <v-list-item v-for="(item, i) in itemsMaster" :key="i" @click="navigateTo(itemsMaster[i].name)">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
      <v-app-bar-title class="white--text"> Dashboard </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-menu bottom offset-y origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list v-if="user">
          <v-list-item dense>
            <v-list-item-title>
              <span class="font-weight-bold primary--text">{{
    user.nama
}}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item dense>
            <v-list-item-title>
              {{ user.role }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item dense link color="primary" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>
  
<script>
import router from "../../router";

// import HelloWorldVue from './components/HelloWorld.vue';
export default {
  name: "MainPageView",
  data: () => ({
    drawer: true,
    selectedItem: 0,
    masterSelectedItem: 0,
    items: [
      { text: "Dashboard", icon: "mdi-home", name: "dashboard" },
      { text: "Pendataan", icon: "mdi-folder", name: "pendataan" },
      { text: "Pendataan Puskesmas", icon: "mdi-hospital-building", name: "penpus" },
      {
        text: "Laporan DBD",
        icon: "mdi-alert",
        name: "laporan-masyarakat",
      },
      {
        text: "Laporan Jentik",
        icon: "mdi-alert-rhombus",
        name: "laporan-jentik",
      },
      { text: "Artikel", icon: "mdi-information", name: "articles" },
      { text: "Pemetaan", icon: "mdi-map", name: "pemetaan" },
      { text: "Notifikasi", icon: "mdi-bell", name: "notification" },
    ],
    itemsMaster: [
      { text: "Desa", icon: "mdi-grass", name: "desa" },
      { text: "Users", icon: "mdi-account-multiple", name: "users" },
      { text: "Puskesmas", icon: "mdi-hospital-building", name: "puskesmas" },
    ],
    user: null,
  }),
  methods: {
    navigateTo(path) {
      if (this.$route.name === path || !path) {
        return;
      }
      router.replace({
        name: path,
      });
    },
    setNav() {
      var paramNames = this.$route.name;
      if (paramNames) {
        if (paramNames.startsWith("dashboard")) {
          this.selectedItem = 0;
        }
        if (paramNames.startsWith("pendataan")) {
          this.selectedItem = 1;
        }

        if (paramNames.startsWith("penpus")) {
          this.selectedItem = 2;
        }

        if (paramNames.startsWith("laporan-masyarakat")) {
          this.selectedItem = 3;
        }

        if (paramNames.startsWith("laporan-jentik")) {
          this.selectedItem = 4;
        }

        if (paramNames.startsWith("article")) {
          this.selectedItem = 5;
        }

        if (paramNames.startsWith("pemetaan")) {
          this.selectedItem = 6;
        }

        if (paramNames.startsWith("notifikasi")) {
          this.selectedItem = 7;
        }

        if (paramNames.startsWith("desa")) {
          this.masterSelectedItem = 0;
          this.selectedItem = -1;
        }
        if (paramNames.startsWith("users")) {
          this.masterSelectedItem = 1;
          this.selectedItem = -1;
        }
      }
    },
    logout() {
      localStorage.setItem("isLogin", false);
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      location.reload();
    },
  },
  updated() {
    this.setNav();
  },
  mounted() {
    this.setNav();

    var user = localStorage.getItem("user");

    this.user = JSON.parse(user);
  },
};
</script>

<style lang="scss">
.v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
  
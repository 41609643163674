<template>
    <v-container class="pa-8">
        <v-row class="pa-4">
            <v-list-item class="text-left">
                <v-list-item-content>
                    <v-list-item-title class="title primary--text">
                        Go Cantik
                    </v-list-item-title>
                    <v-list-item-subtitle>Selamat Datang di Dashboard Aplikasi Go Cantik</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-row>
        <v-row>
            <v-col md="2" xs="12">
                <v-select :items="years" label="Tahun" v-model="selectedYear" v-on:change="onSelectedYearChange" dense
                    outlined>
                </v-select>

            </v-col>
        </v-row>
        <div v-if="!isLoading">
            <v-row>
                <v-col cols="12" md="4">
                    <v-card rounded="lg">
                        <v-card-title>
                            <span>Jumlah Desa</span>
                            <v-spacer></v-spacer>
                            <v-chip color="info">{{ data.totalDesa }}</v-chip>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card rounded="lg">
                        <v-card-title>
                            <span>Jumlah Rumah</span>
                            <v-spacer></v-spacer>
                            <v-chip color="info">{{ data.totalRumah }}</v-chip>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card rounded="lg">
                        <v-card-title>
                            <span>Jumlah Warga</span>
                            <v-spacer></v-spacer>
                            <v-chip color="info">{{ data.jumlahWarga }}</v-chip>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card rounded="lg">
                        <v-card-text>
                            <v-col :align="'start'" class="pa-0">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Rumah Diperiksa</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Jumlah rumah yang telah didata oleh petugas</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="success">
                                                {{ data.rumahDiperiksa }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Rumah Positif</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Jumlah rumah yang dinyatakan memiliki jentik oleh petugas</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="primary">
                                                {{ data.rumahPositif }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Warga Positif</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Jumlah warga yang positif demam berdarah oleh petugas</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="primary">
                                                {{ data.wargaPositif }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card rounded="lg">
                        <v-card-text>
                            <v-col :align="'start'" class="pa-0">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Fasilitas Diperiksa</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Jumlah fasilitas yang telah didata oleh petugas</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="success">
                                                {{ data.fasilitaDiperiksa }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Fasilitas Positif</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Jumlah fasilitas yang dinyatakan memiliki jentik oleh
                                                    petugas</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="primary">
                                                {{ data.fasilitasPositif }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Total Positif</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Jumlah rumah dan fasilitas yang dinyatakan memiliki jentik oleh
                                                    petugas</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="primary">
                                                {{ data.fasilitasPositif + data.rumahPositif }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-card rounded="lg">
                        <v-card-text>
                            <v-col :align="'start'" class="pa-0">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Angka Bebas Jentik</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Angka Bebas Jentik Kabupaten Wajo</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="success">
                                                {{ data.abj.toFixed(2) + '%' }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>

                                </v-list>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card rounded="lg">
                        <v-card-text>
                            <v-col :align="'start'" class="pa-0">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="text-h6">Insiden Rate</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span>Inseden Rate Demam Berdarah Kabupaten Wajo</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-chip color="success">
                                                {{ data.ir.toFixed(2) + '%' }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>

                                </v-list>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

    </v-container>
</template>
<script>
import { BASE_URL } from '@/constant'
import service from '../../services/index'

export default {
    name: 'DashboardView',
    data() {
        return {
            isLoading: true,
            selectedYear: null,
            years: [],
            data: null
        }
    },
    methods: {
        async getData() {
            var query = {
                tahun: this.selectedYear
            }

            var response = await service.getData(BASE_URL + '/pendataan/summary', query)

            if (response.status == 200) {
                this.data = response.data.data

                this.isLoading = false
            }

        },
        async getYears() {
            var response = await service.getData(BASE_URL + '/pendataan/years')

            var years = response.data.data
            if (years) {
                this.years = years.map((year) => {

                    return {
                        text: `${year.year}`,
                        value: year.year
                    }
                })
            }

            this.selectedYear = this.years[this.years.length - 1].value
        },
        async onSelectedYearChange(value) {
            this.selectedYear = value
            this.getData()
        },
    },
    async mounted() {
        await this.getYears()
        await this.getData()
    }
}

</script>
<template>
  <v-container class="px-8 py-8">
    <v-row class="pa-4">
      <v-list-item class="text-left" v-if="!isLoading">
        <v-list-item-content>
          <v-list-item-title class="title primary--text">
            {{ desa.nama }}
          </v-list-item-title>
          <v-list-item-subtitle>{{
            `Kecamatan ${desa.kecamatan}`
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-row>
    <v-row v-if="!isLoading">
      <v-col cols="12" md="6" :align="'start'">
        <v-card rounded-lg class="py-4 px-4">
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Jumlah Rumah
                </v-list-item-title>
                <v-list-item-subtitle
                  >Jumlah rumah di {{ desa.nama }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-chip color="primary">{{ desa.jumlahRumah }}</v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Jumlah Warga
                </v-list-item-title>
                <v-list-item-subtitle
                  >Jumlah warga di {{ desa.nama }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-chip color="primary">{{ desa.jumlahWarga }}</v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Rumah Diperiksa
                </v-list-item-title>
                <v-list-item-subtitle
                  >Jumlah rumah yang telah dikunjungi</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-chip color="success">{{ desa.rumahDiperiksa }}</v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Rumah Positif
                </v-list-item-title>
                <v-list-item-subtitle
                  >Jumlah rumah yang diketahui positif</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-chip color="red white--text">{{
                  desa.jumlahPositif
                }}</v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Warga Positif
                </v-list-item-title>
                <v-list-item-subtitle
                  >Jumlah warga yang diketahui positif</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-chip color="red white--text">{{ desa.wargaPositif }}</v-chip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6"> ABJ </v-list-item-title>
                <v-list-item-subtitle
                  >Angka bebas jentik {{ desa.nama }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-chip color="success white--text"
                  >{{ desa.abj.toFixed(2) }} %</v-chip
                >
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Insiden Rate
                </v-list-item-title>
                <v-list-item-subtitle
                  >Rata-rata insiden pada {{ desa.nama }}</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-chip color="success white--text">{{
                  desa.ir.toFixed(2)
                }}</v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Progress
                </v-list-item-title>
                <v-progress-linear
                  height="25"
                  :value="
                    Math.ceil(
                      (this.desa.rumahDiperiksa / this.desa.jumlahRumah) * 100
                    )
                  "
                  striped
                  color="primary"
                  rounded
                >
                  {{
                    desa.rumahDiperiksa == 0
                      ? 0
                      : Math.ceil(
                          (this.desa.rumahDiperiksa / this.desa.jumlahRumah) *
                            100
                        )
                  }}
                  %
                </v-progress-linear>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" :align="'start'">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="text-h6">List Pendataan</span>
          </v-card-title>
          <v-card-text class="py-0">
            <v-timeline align-top dense>
              <v-timeline-item
                small
                v-for="(data, i) in desa.Pendataan"
                :key="i"
                color="primary"
              >
                <v-card color="primary white--text">
                  <v-card-title class="font-weight-bold subtitle-1">
                    {{
                      `${days[data.date.getDay()]}, ${data.date.getDate()}
                                                                                ${
                                                                                  months[
                                                                                    data.date.getMonth()
                                                                                  ]
                                                                                }
                                                                                ${data.date.getFullYear()}`
                    }}
                  </v-card-title>
                  <v-card-text class="white text--primary">
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Rumah yang diperiksa
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-chip small color="success white--text"
                            >{{ data.totalRumah }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Masyarakat Positif
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-chip small color="primary white--text"
                            >{{ data.wargaPositif }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            >Rumah Positif
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-chip small color="primary white--text"
                            >{{ data.rumahPositif }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-group
                        v-if="data.fasilitasUmum.length > 0"
                        :value="false"
                      >
                        <template v-slot:activator>
                          <v-list-item-title>Fasilitas Umum</v-list-item-title>
                        </template>
                        <v-list-item>
                          <v-list-item-title>
                            Nama Fasilitas
                          </v-list-item-title>

                          <v-list-item-action>
                            Jumlah Positif
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                          v-for="(fasilitasUmum, i) in data.fasilitasUmum"
                          :key="i"
                        >
                          <v-list-item-title>
                            {{
                              `${fasilitasUmum.fasilitasName}
                                                                                                            (${fasilitasUmum.totalFasilitas})`
                            }}
                          </v-list-item-title>

                          <v-list-item-action>
                            <v-chip>{{
                              fasilitasUmum.fasilitasPositif
                            }}</v-chip>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { BASE_URL } from "@/constant";
import service from "../../services/index";

export default {
  props: {
    flush: Object,
  },
  name: "PendataanDetailView",
  data: () => ({
    desa: undefined,
    isLoading: true,
    days: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  methods: {
    async getData(desaId, tahun) {
      var query = {
        desaId,
        year: tahun,
      };
      var response = await service.getData(
        BASE_URL + "/pendataan/bydesa",
        query
      );

      if (response.status == 200) {
        this.desa = response.data.data;

        this.isLoading = false;

        this.parsePendataan;
      }
    },
  },
  mounted() {
    var desaId = this.$route.params.desaId;
    var tahun = this.$route.query.tahun;
    this.getData(desaId, tahun);
  },

  computed: {
    parsePendataan() {
      return this.desa.Pendataan.map((value) => {
        value["date"] = new Date(value.pemeriksaanDate);
        return value;
      });
    },
  },
};
</script>
<template>
  <v-container class="px-8 py-8">
    <v-row class="pa-4">
      <div class="text text-h5 font-weight-bold">Detail Laporan Jentik</div>
    </v-row>
    <v-row :justify="'center'" v-if="!onLoading">
      <v-col cols="12" md="8">
        <v-card rounded="lg">
          <v-card-title class="primary white--text">
            <span class="text-h6">{{ getFormatDateInput }}</span>
          </v-card-title>
          <v-container>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr class="text-left">
                    <td><span>Nama</span></td>
                    <td>
                      <span class="font-weight-bold">{{
                        laporan.user.nama
                      }}</span>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td><span>Desa</span></td>
                    <td>
                      <span class="font-weight-bold">{{
                        laporan.desa.nama
                      }}</span>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td><span>Alamat Lengkap</span></td>
                    <td>
                      <span class="font-weight-bold">{{
                        laporan.user.address
                      }}</span>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td><span>Tanggal Ditemukan</span></td>
                    <td>
                      <span class="font-weight-bold">{{
                        getFormatDateSick
                      }}</span>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td><span>Kontak</span></td>
                    <td>
                      <span class="font-weight-bold">{{
                        laporan.user.phoneNumber
                      }}</span>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td><span>Keterangan</span></td>
                    <td>
                      <span class="font-weight-bold">{{
                        laporan.description
                      }}</span>
                    </td>
                  </tr>
                  <tr class="text-left">
                    <td><span>Status</span></td>
                    <td>
                      <v-chip color="primary">{{ getStatus }}</v-chip>
                    </td>
                  </tr>
                </tbody>
                <v-dialog v-model="showDialog" width="500">
                  <v-card rounded="lg">
                    <v-card-title class="primary white--text">
                      Update Status Laporan
                    </v-card-title>

                    <v-card-text class="py-1">
                      <v-container>
                        <v-row>
                          <v-select
                            color=""
                            :items="items"
                            v-model="selectedItem"
                            dense
                            outlined
                          >
                          </v-select>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="showDialog = false">
                        Batal
                      </v-btn>
                      <v-btn color="primary" @click="updateLaporan">
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-simple-table>
          </v-container>
        </v-card>
        <v-btn
          class="mt-2"
          block
          color="primary"
          @click="showDialog = !showDialog"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "../../services/index.js";

import { BASE_URL } from "../../constant";
import { days } from "../../constant";
import { months } from "../../constant";

export default {
  name: "LaporanJentikDetailView",
  data() {
    return {
      laporan: undefined,
      onLoading: true,
      items: [
        {
          text: "Diterima",
          value: "diterima",
        },
        {
          text: "Diverifikasi",
          value: "diverifikasi",
        },
        {
          text: "Ditindaklanjuti",
          value: "ditindaklanjuti",
        },
        {
          text: "Selesai",
          value: "selesai",
        },
      ],
      showDialog: false,
      selectedItem: undefined,
    };
  },
  methods: {
    async getData(laporanId) {
      var response = await service.getData(
        BASE_URL + "/laporan-jentik/detail/" + laporanId,
        null
      );

      if (response.status == 200) {
        this.laporan = response.data.data;

        this.onLoading = false;

        this.parseData;

        this.selectedItem = this.items[this.setSelectedItem];
      }
    },
    async updateLaporan() {
      console.log(this.selectedItem);
      var data = {
        status: this.selectedItem,
      };

      this.showDialog = false;
      var response = await service.updateData(
        BASE_URL + "/laporan-jentik/update/" + this.laporan.id,
        data
      );
      if (response.status == 200) {
        console.log(response.data);
        location.reload();
      }
    },
  },
  mounted() {
    var laporanId = this.$route.params.laporanId;
    this.getData(laporanId);
  },
  computed: {
    getFormatDateInput() {
      var laporanDate = new Date(this.laporan.createdAt);
      return `${days[laporanDate.getDay()]}, ${laporanDate.getDate()} ${
        months[laporanDate.getMonth()]
      } ${laporanDate.getFullYear()}`;
    },
    getFormatDateSick() {
      var laporanDate = new Date(this.laporan.dateFound);
      return `${days[laporanDate.getDay()]}, ${laporanDate.getDate()} ${
        months[laporanDate.getMonth()]
      } ${laporanDate.getFullYear()}`;
    },
    setSelectedItem() {
      return this.items.findIndex((item) => item.value == this.laporan.status);
    },
    getStatus() {
      var text = this.laporan.status;
      return text[0].toUpperCase() + text.substring(1);
    },
  },
};
</script>